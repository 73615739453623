import alt from '../alt';
import ContactUsActions from '../actions/ContactUsActions';

class ContactUsStore {
  constructor() {
    this.bindActions(ContactUsActions);
    this.name = '';
    this.emailAddress = '';
    this.message = '';
    this.nameHelpBlock = '';
    this.messageHelpBlock = '';
    this.nameValidationState = '';
    this.emailAddressValidationState = '';
    this.messageValidationState = '';
  }

  onAddMessageSuccess(successMessage) {
    this.nameValidationState = '';
    this.emailAddressValidationState = '';
    this.messageValidationState = '';
    this.name = '';
    this.emailAddress = '';
    this.message = '';
    this.nameHelpBlock = '';
    this.messageHelpBlock = '';
    toastr.success(successMessage);

  }

  onAddMessageFail(errorMessage) {
    this.messageValidationState = 'has-error';
    this.emailAddressValidationState = 'has-error';
    this.messageValidationState = 'has-error';
    this.nameHelpBlock = '';
    this.messageHelpBlock = '';
    toastr.error(errorMessage);
  }

  onUpdateName(event) {
    this.name = event.target.value;
    this.nameValidationState = '';
    this.nameHelpBlock = '';
  }

  onUpdateEmailAddress(event) {
    this.emailAddress = event.target.value;
    this.emailAddressValidationState = '';
  }

  onUpdateMessage(event) {
    this.message = event.target.value;
    this.messageValidationState = '';
    this.messageHelpBlock = '';
  }

  onInvalidName() {
    this.nameValidationState = 'has-error';
    this.nameHelpBlock = 'The name can not be empty.';
  }

  onInvalidEmailAddress() {
    this.emailAddressValidationState = 'has-error';
  }

  onInvalidMessage() {
    this.messageValidationState = 'has-error';
    this.messageHelpBlock = 'The message can not be less than 20 characters.';
  }
}

export default alt.createStore(ContactUsStore);

