import React from 'react';
import {Link} from 'react-router';
import ContactUs from '../ContactUs';
import ExcelContactsNavBarStoreActions from '../../actions/excelcontacts/NavBarActions';

class ExcelContactsHome extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
     ExcelContactsNavBarStoreActions.gotoHome();
  }

  componentWillUnmount() {
  }

  render() {

    
    return (
      <div>
        <section id="samapp-carousel" className="samapp-carousel carousel slide">

          <div className="carousel-inner">
            <div className="item active">
               <div className="samapp-promo sa-contacts-promo">
                <div className="row">
                  <div className="overview col-md-8 col-sm-12 col-xs-12">
                    <h2 className="title sa-contacts">Export contacts from iPhone or Android to Excel</h2>
                    <p className="summary sa-contacts">You can use the spreadsheet to keep all your contacts in one place. It’s the best way to transfer contacts to and from your smartphone.</p>
                    <div className="download-area">
                        <ul className="btn-group list-inline">
                            <li className="ios-btn"><a href="https://itunes.apple.com/app/sa-contacts-lite-export-import/id385462419?mt=8">Download from the App Store</a></li>
                            <li className="android-btn"><a href="https://play.google.com/store/apps/details?id=com.samapp.excelcontacts.excelcontactslite">Get it from Google Play</a></li>
                        </ul>
                    </div>
                  </div>
                </div>

               </div>
            </div>
          </div>

        </section>

        <section id="features" className="app-features section offset-header">
          <div className="container">
              <div className="row">
                <div className="item col-md-9 col-sm-12 col-xs-12">
                  <div className="feature-screenshot pull-left">
                    <img src={'/img/excelcontacts/ec-feature-1.jpg'} className='screenshot'/>
                  </div>
                  <div className="feature-desc">
                    <h2 className="title">Export Contacts to Excel
                    </h2>
                    <p>
                    7 formats are available and meet needs of transferring contacts between iPhone and Excel, Gmail, Outlook, android Phones and so on.<br/>
                    Never again rely on iTunes as your only local source of back up. Nor to share your contacts with Yahoo or other websites in order to have a backup.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="item col-md-offset-3 col-md-9 col-sm-12 col-xs-12">
                  <div className="feature-screenshot pull-right">
                    <img src={'/img/excelcontacts/ec-feature-2.jpg'} className='screenshot'/>
                  </div>
                  <div className="feature-desc">
                    <h2 className="title">Different transfer ways
                    </h2>
                    <p>
                    You can transfer files from/to ExcelContacts via USB (iTunes), Wi-Fi, Email, Dropbox, Google Drive, One drive or other apps.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="item col-md-9 col-sm-12 col-xs-12">
                  <div className="feature-screenshot pull-left">
                    <img src={'/img/excelcontacts/ec-feature-3.jpg'} className='screenshot'/>
                  </div>
                  <div className="feature-desc">
                    <h2 className="title">Export/Import Photos
                    </h2>
                    <p>
                    ExcelContacts provides two ways to export/import photos:<br/>
                    Insert photos in Excel direclty or package photos and Excel file into a ZIP file.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="item col-md-offset-3 col-md-9 col-sm-12 col-xs-12">
                  <div className="feature-screenshot pull-right">
                    <img src={'/img/excelcontacts/ec-feature-4.jpg'} className='screenshot'/>
                  </div>
                  <div className="feature-desc">
                    <h2 className="title">Import custom Excel files
                    </h2>
                    <p>
                    If the column headers of your import file are defined by yourself, this app also allow you to import it by doing a field mapping. You can tell the app which column corresponds to which field.
                    </p>
                  </div>
                </div>
              </div>
            </div>
        </section>
        <section id="download" className="app-features section">
          <div className="container">
              <div className="row">
                <div className="item col-md-12 col-sm-12 col-xs-12 text-center">
                  <h2 className="title">What an Excel spreadsheet looks like</h2>
                </div>
              </div>
              <div className="row">
                <div className="item col-md-12 col-sm-12 col-xs-12 text-center">
                  <img src={'/img/excelcontacts/ec-spreadsheet-1.jpg'} className='spreadsheet img-responsive center-block'/>
                </div>
              </div>

              <div className="row">
                <div className="item col-md-12 col-sm-12 col-xs-12 text-center">
                  <h3 className="title">Here's some sample files, you can choose one of them as a template.</h3>
                </div>
              </div>
              <div className="row">
                <div className="item col-md-3 col-sm-4 col-xs-6">
                  <Link to="/download/excelcontacts/sample1.xls" target="_blank">
                    <div className="downloadfile-icon pull-left">
                      <img src={'/img/filetype-xls.png'} className='icon'/>
                    </div>
                    <div className="downloadfile-desc">
                      <p>
                      Sample1.xls<br/>
                      File format: Mixed<br/>
                      File size: 189 Kb<br/>
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="item col-md-3 col-sm-4 col-xs-6">
                  <Link to="/download/excelcontacts/sample2.zip" target="_blank">
                    <div className="downloadfile-icon pull-left">
                      <img src={'/img/filetype-zip.png'} className='icon'/>
                    </div>
                    <div className="downloadfile-desc">
                      <p>
                      Sample2.zip<br/>
                      File format: Separated<br/>
                      File size: 169 Kb<br/>
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="item col-md-3 col-sm-4 col-xs-6">
                  <Link to="/download/excelcontacts/sample3.xls" target="_blank">
                    <div className="downloadfile-icon pull-left">
                      <img src={'/img/filetype-xls.png'} className='icon'/>
                    </div>
                    <div className="downloadfile-desc">
                      <p>
                      Sample3.xls<br/>
                      File format: Outlook<br/>
                      File size: 7 Kb<br/>
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="item col-md-3 col-sm-4 col-xs-6">
                  <Link to="/download/excelcontacts/sample4.csv" target="_blank">
                    <div className="downloadfile-icon pull-left">
                      <img src={'/img/filetype-xls.png'} className='icon'/>
                    </div>
                    <div className="downloadfile-desc">
                      <p>
                      Sample4.csv<br/>
                      File format: Gmail<br/>
                      File size: 1 Kb<br/>
                      </p>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="row">
                <div className="item col-md-12 col-sm-12 col-xs-12 text-center">
                  <h3 className="title">Column headers</h3>
                </div>
              </div>
              <div className="row">
                <div className="item col-md-12 col-sm-12 col-xs-12 text-center">
                  <img src={'/img/excelcontacts/ec-column-headers.jpg'} className='spreadsheet img-responsive center-block'/>
                </div>
              </div>

          </div>
        </section>

        <section id="faq" className="faq section">
          <div className="container">
            <div className="row">
              <h2 className="title text-center">Frequently Asked Questions</h2>
                <div className="faq-col col-md-6 col-sm-6 col-xs-12">
                    <div className="item">
                        <h3 className="question"><i className="fa fa-question-circle"></i>Where is File Sharing located in iTunes?</h3>
                        <p className="answer">Connect your iPhone, iPad, or iPod touch to a PC or Mac with iTunes installed via a USB cable. Launch the iTunes application, unlock your iOS device, and click its icon on the toolbar in iTunes. <br/>
                        Select the "Apps" category in the sidebar and scroll down in the right pane. After the list of home screens, you’ll eventually come to the “File Sharing” section.</p>
                    </div>
                    <div className="item">
                        <h3 className="question"><i className="fa fa-question-circle"></i>Why can the excel file not be imported?</h3>
                        <p className="answer">First please make sure the file is .xls file, not .xlsx file.<br/>
                        Next please make sure the column headers can be recognized by the app. Here are all valid column headers.<br/>
                        If you still cannot figure out what is going wrong, please contact us directly.</p>
                    </div>
                    <div className="item">
                        <h3 className="question"><i className="fa fa-question-circle"></i>How can I transfer the file from my computer to my phone?</h3>
                        <p className="answer">There’s lots of way to transfer the file from your computer to your phone. One way is via Email. Send an email with your file attached, then on your phone open the email, tap the attachment and choose SA Contacts to open in.<br/>
                        Another easy way is via WiFi, make sure your computer and your phone are in the same local network, otherwise it would not work properly.</p>
                    </div>
                </div>
                <div className="faq-col col-md-6 col-sm-6 col-xs-12">
                    <div className="item">
                        <h3 className="question"><i className="fa fa-question-circle"></i>Why does the app export a zip file instead of an excel file?</h3>
                        <p className="answer">Because the app also exports photos of contacts, so it compresses the excel file and photos file into a zip file.<br/>
                        You can turn off “Photos” or turn on “Photos in Excel” if you want to get an excel file instead of a zip file.<br/>
                        Please note if you turn off “Photos”, the app would not export contact’s photos.</p>
                    </div>
                    <div className="item">
                        <h3 className="question"><i className="fa fa-question-circle"></i>I purchased this app with iTunes and i now am using an Android phone, can i use my purchased app through Android?</h3>
                        <p className="answer">Sorry there are independent app markets, and it’s impossible to purchase an app and use it across all app markets.<br/>
                        But we can give you a promo code, the promo code can be used one time to download the app freely.</p>
                    </div>
                </div>
            </div>
            <div className="more text-center">
                <h4 className="title">More questions?</h4>
                <a className="btn btn-lg btn-theme" href="mailto:support@samapp.com">Get in touch<i className="fa fa-arrow-circle-o-down"></i></a>
            </div>
          </div>
        </section>

        <section id="testimonials" className="testimonials section">
          <div className="container">
            <div class="row">
                <h2 className="title text-center">What do people think?</h2>
                <div className="item col-md-4 col-sm-4">
                    <div className="quote-box">
                        <i className="fa fa-quote-left"></i>
                        <blockquote className="quote">
                            The best ever Contacts Manager for Android/iOS. Contacts are fully compatible between two OS's. Never ever had a problem to synchronize my Android and iPhone. Developer is very friendly and always answers to any questions.
                        </blockquote>
                    </div>
                    <div className="people row">
                        <img className="img-rounded user-pic col-md-5 col-sm-5 col-xs-12 col-md-offset-1 col-sm-offset-1" src="/img/excelcontacts/person-1.png" alt="" />
                        <p className="details text-center pull-left">
                            <span className="name">Alex Kornev</span>
                        </p>                        
                    </div>
                </div>
                <div className="item col-md-4 col-sm-4">
                    <div className="quote-box">
                        <i className="fa fa-quote-left"></i>
                        <blockquote className="quote">
                            This brilliant App has worked perfectly on several iPods and iPads for many years. Regularly updated, it offers a variety of backup formats and destination options. One little hint - when you want to use the App with an iPad: Lock the iPad in portrait mode first. As iOS gadgets get cleverer, this app keeps up - well done!
                        </blockquote>
                    </div>
                    <div className="people row">
                        <img className="img-rounded user-pic col-md-5 col-sm-5 col-xs-12 col-md-offset-1 col-sm-offset-1" src="/img/excelcontacts/person-2.png" alt="" />
                        <p className="details text-center pull-left">
                            <span className="name">Pat.66</span>
                        </p>                        
                    </div>
                </div>
                <div className="item col-md-4 col-sm-4">
                    <div className="quote-box">
                        <i className="fa fa-quote-left"></i>
                        <blockquote className="quote">
                            Finally Found the One! Stop looking and press the Install button now. You've found the right one. I downloaded almost a dozen Contacts to Excel apps before I found this one. It does everything I wanted; putting every field in Contacts into an Excel spreadsheet. I use my Contacts to keep track of so much more information than just phone numbers, but the other developers didn't realize that. I can even sort on Groups, because the Groups field is a cell.
                        </blockquote>
                    </div>
                    <div className="people row">
                        <img className="img-rounded user-pic col-md-5 col-sm-5 col-xs-12 col-md-offset-1 col-sm-offset-1" src="/img/excelcontacts/person-3.png" alt="" />
                        <p className="details text-center pull-left">
                            <span className="name">Will Sander</span>
                        </p>                        
                    </div>
                </div>
            </div>

            <div class="row">
                <div className="item col-md-4 col-sm-4">
                    <div className="quote-box">
                        <i className="fa fa-quote-left"></i>
                        <blockquote className="quote">
                            "A must have" app It's work without issues on my i9300, 4.4.4 android custom rom. Simple and efficiently. I love the wifi upload options and the rest. Many thanks and please always improve and make conpatible with other windows excell versions.
                        </blockquote>
                    </div>
                    <div className="people row">
                        <img className="img-rounded user-pic col-md-5 col-sm-5 col-xs-12 col-md-offset-1 col-sm-offset-1" src="/img/excelcontacts/person-4.png" alt="" />
                        <p className="details text-center pull-left">
                            <span className="name">Aanei Daniel</span>
                        </p>                        
                    </div>
                </div>
                <div className="item col-md-4 col-sm-4">
                    <div className="quote-box">
                        <i className="fa fa-quote-left"></i>
                        <blockquote className="quote">
                            works like a charm just did a hard reset of my phone, and this app came in handy! cleaned up my bloated contact list and did an import successfully.  oh btw, the admin is also quick to respond to bug fix. great job!
                        </blockquote>
                    </div>
                    <div className="people row">
                        <img className="img-rounded user-pic col-md-5 col-sm-5 col-xs-12 col-md-offset-1 col-sm-offset-1" src="/img/excelcontacts/person-5.png" alt="" />
                        <p className="details text-center pull-left">
                            <span className="name">Y H Tan</span>
                        </p>                        
                    </div>
                </div>
                <div className="item col-md-4 col-sm-4">
                    <div className="quote-box">
                        <i className="fa fa-quote-left"></i>
                        <blockquote className="quote">
                            THIS APP SAVED MY LIFE!!!! I went to the phone store, not naming names, and they could not help me and only made it worse. I could not fix the problem with apple products either. I was missing contacts from my old phone to my new phone and I could not fix the issue. There were way way too many to try and add the missing contacts to my new phone. $3 and a few clicks and my life is back. Thank you
                        </blockquote>
                    </div>
                    <div className="people row">
                        <img className="img-rounded user-pic col-md-5 col-sm-5 col-xs-12 col-md-offset-1 col-sm-offset-1" src="/img/excelcontacts/person-6.png" alt="" />
                        <p className="details text-center pull-left">
                            <span className="name">Bullocou</span>
                        </p>                        
                    </div>
                </div>
            </div>

          </div>
        </section>

      </div>
    );
  }
}

export default ExcelContactsHome;