import React from 'react';
import {Link} from 'react-router';
import ExcelSMSNavBarStore from '../../stores/excelsms/NavBarStore';
import ExcelSMSNavBarStoreActions from '../../actions/excelsms/NavBarActions';

class ExcelSMSNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = ExcelSMSNavBarStore.getState();
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    ExcelSMSNavBarStore.listen(this.onChange);
  }

  componentWillUnmount() {
    ExcelSMSNavBarStore.unlisten(this.onChange);
  }

  onChange(state) {
    this.setState(state);
  }

  render() {
    
    return (
      <nav className='samapp-navbar navbar navbar-default navbar-static-top'>
        <div className='navbar-header'>
          <button type='button' className='navbar-toggle collapsed' data-toggle='collapse' data-target='#navbar'>
            <span className='sr-only'>Toggle navigation</span>
            <span className='icon-bar'></span>
            <span className='icon-bar'></span>
            <span className='icon-bar'></span>
          </button>
          <a className="scrollto navbar-brand" href="/excelsms">
              <img src={'/img/excelsms.png'} className='icon'/>
          </a>
          <a className="scrollto navbar-brand" href="/excelsms"><div className="navbar-title">SA Group Text</div></a>
          
        </div>
        <div id='navbar' className='navbar-collapse collapse'>
          <ul className='nav navbar-nav navbar-right'>
                <li className="nav-item"><a href={"/excelsms/privacy"}>Privacy</a></li>
                <li className="nav-item"><a className="scrollto" href={"#features"}>Features</a></li>
                <li className="nav-item"><a className="scrollto" href={"#download"}>Download</a></li>
                <li className="nav-item"><a className="scrollto" href={"#faq"}>FAQ</a></li>
          </ul>
        </div>
      </nav>


    );
  }
}

export default ExcelSMSNavbar;

