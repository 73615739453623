import React from 'react';
import {Link} from 'react-router';
import ContactUs from '../ContactUs';
import TeamContactsNavBarStoreActions from '../../actions/teamcontacts/NavBarActions';

class TeamContactsHome extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
     TeamContactsNavBarStoreActions.gotoHome();
  }

  componentWillUnmount() {
  }

  render() {

    
    return (
      <div>
        <section id="samapp-carousel" className="samapp-carousel carousel slide">

          <div className="carousel-inner">
            <div className="item active">
               <div className="samapp-promo team-contacts-promo">
                <div className="row">
                  <div className="overview col-md-8 col-sm-12 col-xs-12">
                    <h2 className="title team-contacts">TeamContacts - Keep your team contacts up to date</h2>
                    <p className="summary team-contacts">Keep all your team’s contact information in one place and reach all team members at once. All team members can get the contacts across all the devices they use.</p>
                    <div className="download-area">
                        <ul className="btn-group list-inline">
                            <li className="ios-btn"><a href="#">Download from the App Store</a></li>
                            <li className="android-btn"><a href="#">Get it from Google Play</a></li>
                        </ul>
                    </div>
                  </div>
                </div>

               </div>
            </div>
          </div>

        </section>

        <section id="features" className="app-features section offset-header">
          <div className="container">
              <div className="row">
                <div className="item col-md-6 col-sm-6 col-xs-12">
                  <div className="feature-desc">
                    <h2 className="title">Secure and Private
                    </h2>
                    <p>
                    With TeamContacts App your team’s contact information goes directly to the relevant members, and is completely secure and private. TeamContacts encrypts your data before saving it on our server, the encryption key is combined with your password to harden the encryption of your data.
                    </p>
                  </div>
                </div>

                <div className="item col-md-6 col-sm-6 col-xs-12 pull-right">
                  <div className="feature-screenshot">
                    <img src={'/img/teamcontacts/tc-feature-0.jpg'} className='screenshot-landscape'/>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="item col-md-12 col-sm-12 col-xs-12">
                  <div className="feature-screenshot pull-right overly-box">
                    <img src={'/img/teamcontacts/tc-feature-1.jpg'} className='screenshot overly-1'>
                    <img src={'/img/teamcontacts/tc-feature-2.jpg'} className='screenshot overly-2'/></img>
                  </div>
                  <div className="feature-desc">
                    <h2 className="title">Import contacts from Excel
                    </h2>
                    <p>
                    It’s also a piece of cake to import your contacts to TeamContacts. First you need to maintain your contacts in an Excel spreadsheet. Next transfer the Excel file to your phone and let TeamContacts import contacts from it. Then your contacts will be uploaded to the server so that all your team members can access your team’s address book.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="item col-md-12 col-sm-12 col-xs-12">
                  <div className="feature-screenshot pull-right overly-box">
                    <img src={'/img/teamcontacts/tc-feature-3.jpg'} className='screenshot overly-1'>
                    <img src={'/img/teamcontacts/tc-feature-4.jpg'} className='screenshot overly-2'/></img>
                  </div>
                  <div className="feature-desc">
                    <h2 className="title">Sharing contacts across your team
                    </h2>
                    <p>
                    By sharing contacts across your team, it became a lot easier for the right people to get in touch with each other. Everyone in your team can access to up to date contact details.<br/>
                    TeamContacts tracks the changes you make to your team’s address book. Everyone can see who has joined the team, who has left the team and who’s contact info has been updated.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="item col-md-12 col-sm-12 col-xs-12">
                  <div className="feature-screenshot pull-right overly-box">
                    <img src={'/img/teamcontacts/tc-feature-5.jpg'} className='screenshot overly-1'/>
                    <img src={'/img/teamcontacts/tc-feature-6.jpg'} className='screenshot overly-2'></img>
                  </div>
                  <div className="feature-desc">
                    <h2 className="title">Maintain control over the Merge process
                    </h2>
                    <p>
                    TeamContacts gives you an easy way to merge your contacts with the contacts on your phone. You can maintain control over the merge process. When you do a merge, you are able to review duplicates and decide to merge or not to merge each record on an individual basis.
                    </p>
                  </div>
                </div>
              </div>
            </div>
        </section>
        <section id="download" className="app-features section">
          <div className="container">
              <div className="row">
                <div className="item col-md-12 col-sm-12 col-xs-12 text-center">
                  <h2 className="title">What an Excel spreadsheet looks like</h2>
                </div>
              </div>
              <div className="row">
                <div className="item col-md-12 col-sm-12 col-xs-12 text-center">
                  <h4 className="title">Example 1</h4>
                </div>
              </div>
              <div className="row">
                <div className="item col-md-12 col-sm-12 col-xs-12 text-center">
                  <img src={'/img/teamcontacts/tc-spreadsheet-1.jpg'} className='spreadsheet img-responsive center-block'/>
                </div>
                <div className="item col-md-12 col-sm-12 col-xs-12 text-center">
                  <Link to="/download/teamcontacts/sample1.xls" target="_blank"><div className="download-btn pull-right">Download</div>
                  </Link>
                </div>
              </div>

              <div className="row">
                <div className="item col-md-12 col-sm-12 col-xs-12 text-center">
                  <h4 className="title">Example 2</h4>
                </div>
              </div>
              <div className="row">
                <div className="item col-md-12 col-sm-12 col-xs-12 text-center">
                  <img src={'/img/teamcontacts/tc-spreadsheet-2.jpg'} className='spreadsheet img-responsive center-block'/>
                </div>
                <div className="item col-md-12 col-sm-12 col-xs-12 text-center">
                  <Link to="/download/teamcontacts/sample2.zip" target="_blank"><div className="download-btn pull-right">Download</div>
                  </Link>
                </div>
              </div>

          </div>
        </section>

        <section id="faq" className="faq section">
          <div className="container">
            <div className="row">
              <h2 className="title text-center">Frequently Asked Questions</h2>
                <div className="faq-col col-md-6 col-sm-6 col-xs-12">
                    <div className="item">
                        <h3 className="question"><i className="fa fa-question-circle"></i>How can I import an Excel file from my computer to TeamContacts?</h3>
                        <p className="answer">You can send an email with your file attached, then open the email on your phone, and tap the attachment, choose TeamContacts Admin to open in.<br/>
If you have Mac computer and iPhone, you can also import via File Sharing or Airdrop. If you have Android phone, you can import the file from SD card or internal storage in your phone. Please click here for more details.
                        </p>
                    </div>
                    <div className="item">
                        <h3 className="question"><i className="fa fa-question-circle"></i>Where is File Sharing located in iTunes?</h3>
                        <p className="answer">Connect your iPhone, iPad, or iPod touch to a PC or Mac with iTunes installed via a USB cable. Launch the iTunes application, unlock your iOS device, and click its icon on the toolbar in iTunes.<br/>
Select the “Apps” category in the sidebar and scroll down in the right pane. After the list of home screens, you’ll eventually come to the “File Sharing” section.
                        </p>
                    </div>
                    <div className="item">
                        <h3 className="question"><i className="fa fa-question-circle"></i>Can TeamContacts import the file which was exported from SA Contacts?</h3>
                        <p className="answer">Yes, but you have to choose Separated format to export in SA Contacts. TeamContacts can only support the Separated format.</p>
                    </div>
                </div>
                <div className="faq-col col-md-6 col-sm-6 col-xs-12">
                    <div className="item">
                        <h3 className="question"><i className="fa fa-question-circle"></i>What's the difference between TeamContacts Admin and TeamContacts Member?</h3>
                        <p className="answer">TeamContacts Admin can create address book and update the address book. it's should be used by the team leader or adminstrator, such as HR manager. TeamContacts Member is used by team members, it can download the address book and sync with the contacts on the device.
                        </p>
                    </div>
                    <div className="item">
                        <h3 className="question"><i className="fa fa-question-circle"></i>Should all team members sign up to create an account in TeamContacts?</h3>
                        <p className="answer">It's not necessary to sign up in TeamContacts Member. Only TeamContacts Admin need an account.</p>
                    </div>
                </div>
            </div>
            <div className="more text-center">
                <h4 className="title">More questions?</h4>
                <a className="btn btn-lg btn-theme" href="mailto:support@samapp.com">Get in touch<i className="fa fa-arrow-circle-o-down"></i></a>
            </div>
          </div>
        </section>


        <section id="pricing" className="pricing section has-pattern">
          <div className="container">
               <div className="price-cols row">
                  <h2 className="title text-center">Pricing</h2>
                  <div className="items-wrapper col-md-10 col-sm-12 col-xs-12 col-md-offset-1 col-sm-offset-0 col-xs-offset-0">
                      <div className="item price-1 col-md-4 col-sm-4 col-xs-12 text-center">
                          <div className="item-inner">
                              <div className="heading">
                              <h3 className="title">Basic</h3>
                                  <p className="price-figure"><span class="price-figure-inner"><span class="currency">$</span><span class="number">1.99</span><br /><span class="unit"> per month</span></span></p>
                              </div>
                              <div className="content">
                                  <ul className="list-unstyled feature-list">
                                      <li><i className="fa fa-check"></i>100 members</li>
                                      <li><i className="fa fa-check"></i>Email priority support</li>
                                      <li><i className="fa fa-check"></i>Free update</li>
                                      <li><i className="fa fa-check"></i>24/7 support</li>
                                  </ul>
                              </div>
                          </div>
                      </div> 
                      
                      <div className="item price-2 col-md-4 col-sm-4 col-xs-12 text-center best-buy">
                          <div className="item-inner">
                              <div className="heading">
                              <h3 className="title">Small</h3>
                                  <p className="price-figure"><span class="price-figure-inner"><span class="currency">$</span><span class="number">3.99</span><br /><span class="unit">per month</span></span></p>
                              </div>
                              <div className="content">
                                  <ul className="list-unstyled feature-list">
                                      <li><i className="fa fa-check"></i>200 members</li>
                                      <li><i className="fa fa-check"></i>Email priority support</li>
                                      <li><i className="fa fa-check"></i>Free update</li>
                                      <li><i className="fa fa-times"></i>24/7 support</li>
                                      
                                  </ul>
                              </div>
                              <div className="ribbon">
                                  <div className="text">Popular</div>
                              </div>
                          </div>
                      </div>  
                      
                      <div className="item price-3 col-md-4 col-sm-4 col-xs-12 text-center">
                          <div className="item-inner">
                              <div className="heading">
                                  <h3 className="title">Medium</h3>
                                  <p className="price-figure"><span class="price-figure-inner"><span class="currency">$</span><span class="number">6.99</span><br /><span class="unit">per month</span></span></p>
                              </div>
                              <div className="content">
                                  <ul className="list-unstyled feature-list">
                                      <li><i className="fa fa-check"></i>400 members</li>
                                      <li><i className="fa fa-check"></i>Email priority support</li>
                                      <li><i className="fa fa-check"></i>Free update</li>
                                      <li><i className="fa fa-check"></i>24/7 support</li>                                  
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>                  
              </div>
          </div>
        </section>

      </div>
    );
  }
}

export default TeamContactsHome;


