import React from 'react';
import {Link} from 'react-router';

class TCSingleNavBar extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    
    return (
      <nav className='samapp-navbar navbar navbar-default navbar-static-top'>
        <div className='navbar-header'>
          <button type='button' className='navbar-toggle collapsed' data-toggle='collapse' data-target='#navbar'>
            <span className='sr-only'>Toggle navigation</span>
            <span className='icon-bar'></span>
            <span className='icon-bar'></span>
            <span className='icon-bar'></span>
          </button>
          <a className="navbar-brand" href="/teamcontacts">
              <img src={'/img/teamcontacts.png'} className='icon'/>
          </a>
          <a className="navbar-brand" href="/teamcontacts"><div className="navbar-title">TeamContacts</div></a>
          
        </div>
        <div id='navbar' className='navbar-collapse collapse'>
          <ul className='nav navbar-nav navbar-right'>
                <li className="nav-item"><a href={"/teamcontacts#features"}>Features</a></li>
                <li className="nav-item"><a href={"/teamcontacts#download"}>Download</a></li>
                <li className="nav-item"><a href={"/teamcontacts#faq"}>FAQ</a></li>
          </ul>
        </div>
      </nav>


    );
  }
}

export default TCSingleNavBar;