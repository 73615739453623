import React from 'react';
import SingleNavBar from './SingleNavBar';
import Footer from './Footer';

class SinglePage extends React.Component {
  render() {
    return (
      <div>
        <SingleNavBar history={this.props.history} />
        {this.props.children}
        <Footer />
      </div>
    );
  }
}

export default SinglePage;