import alt from '../alt';

class ContactUsActions {
  constructor() {
    this.generateActions(
      'addMessageSuccess',
      'addMessageFail',
      'updateName',
      'updateEmailAddress',
      'updateMessage',
      'invalidName',
      'invalidEmailAddress',
      'invalidMessage'
    );
  }

  addMessage(name, emailAddress, message) {
    $.ajax({
      type: 'POST',
      url: '/api/contactus',
      data: { name: name, email_address: emailAddress, message: message }
    })
      .done((data) => {
        this.actions.addMessageSuccess(data.message);
      })
      .fail((jqXhr) => {
        this.actions.addMessageFail(jqXhr.responseJSON.message);
      });
  }
}

export default alt.createActions(ContactUsActions);