import React from 'react';
import {Link} from 'react-router';
import {first, without, findWhere} from 'underscore';


class PrivacyHome extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  handleClick(app) {
  }

  render() {

    
    return (
      <div>
        
        <section id="terms" className="text-body section">
          <div className="container">
              <div className="row">
                <div className="item col-md-12 col-sm-12 col-xs-12">
                    <h2 className="title">Privacy Policy
                    </h2>
                </div>
                <div className="item col-md-12 col-sm-12 col-xs-12">
<p>SamApp Co., Ltd. ​("us", "we", or "our") operates SamApp website www.samapp.com and mobile applications ​(the "Site" or "SamApp"). This page informs you of our policies regarding the collection, use and disclosure of Personal Information we receive from users of the Site.</p>

<h4 className="sub-title">Information Collection And Use</h4>
<p>While using SamApp, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to your user name and email address ("Personal Information").</p>

<h4 className="sub-title">Disclosure</h4>
<p>We will not disclose personal information to a third party without the informed consent of the individual. We will ensure that the personal information of users and customers will not be disclosed to any other institutions or authorities except if required to by law or other regulation.</p>

<h4 className="sub-title">Email Details</h4>
<p>We will only record an individual's e-mail address if they send us an e-mail message. It will only be used for the purposes for which the individual provides it, and will not be added to a mailing list unless the individual requests that this is done. We will not disclose it without the individual's consent.</p>

<h4 className="sub-title">Log Data</h4>
<p>We collect information that your browser sends whenever you visit our Site ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the time and date of your visit, the time spent on those pages and other statistics.</p>

<h4 className="sub-title">Web browser cookies</h4>
<p>Our Site may use "cookies" to enhance User experience. User's web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. User may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the Site may not function properly.</p>

<h4 className="sub-title">Security</h4>
<p>We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site.</p>

<p>Sensitive and private data exchange between the Site and its Users happens over a SSL secured communication channel and is encrypted and protected with digital signatures.</p>

<h4 className="sub-title">Changes to this privacy policy</h4>
<p>SamApp Co., Ltd. has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page and send you an email. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.</p>

<h4 className="sub-title">Your acceptance of these terms</h4>
<p>By using SamApp, you signify your acceptance of this policy. If you do not agree to this policy, please do not use SamApp. Your continued use of SamApp following the posting of changes to this policy will be deemed your acceptance of those changes.</p>

<h4 className="sub-title">Contacting us</h4>
<p>If you have any questions about the Privacy Statement, the practices of SamApp, or your dealings with samapp, please contact us at support@samapp.com.</p>

<p>This document was last updated on Jun 21, 2015.</p>

                </div>
              </div>
          </div>
        </section>
      </div>
    );
  }
}

export default PrivacyHome;

