import alt from '../../alt';
import TeamContactsNavBarActions from '../../actions/teamcontacts/NavBarActions';

class TeamContactsNavBarStore {
  constructor() {
    this.bindActions(TeamContactsNavBarActions);
    this.isHome = true;
    this.isFaq = false;
    this.isManual = false;
  }

  onGotoHome() {
    this.isHome = true;
    this.isFaq = false;
    this.isManual = false;
  }

  onGotoFaq() {
    this.isHome = false;
    this.isFaq = true;
    this.isManual = false;
  }

  onGotoManual() {
    this.isHome = false;
    this.isFaq = false;
    this.isManual = true;
  }

}

export default alt.createStore(TeamContactsNavBarStore);

