import React from 'react';
import {Link} from 'react-router';
import NavBarStore from '../stores/NavBarStore';
import NavBarActions from '../actions/NavBarActions';

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = NavBarStore.getState();
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    NavBarStore.listen(this.onChange);
  }

  componentWillUnmount() {
    NavBarStore.unlisten(this.onChange);
  }

  onChange(state) {
    this.setState(state);
  }

  render() {
    return (
      <nav className='samapp-navbar navbar navbar-default navbar-static-top'>
        <div className='navbar-header'>
          <button type='button' className='navbar-toggle collapsed' data-toggle='collapse' data-target='#navbar'>
            <span className='sr-only'>Toggle navigation</span>
            <span className='icon-bar'></span>
            <span className='icon-bar'></span>
            <span className='icon-bar'></span>
          </button>
          <Link to='/' className='navbar-brand'>
            <img src={'/img/samapp.png'} className='icon'/>
          </Link>
        </div>
        <div id='navbar' className='navbar-collapse collapse'>
          <ul className='nav navbar-nav navbar-right'>
            <li><Link to='#features' className="scrollto">Products</Link></li>
            <li><Link to='#contact' className="scrollto">Contact</Link></li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default NavBar;