import React from 'react';
import ExcelContactsNavbar from './NavBar';
import Footer from '../Footer';

class ExcelContactsApp extends React.Component {
  render() {
    return (
      <div>
        <ExcelContactsNavbar history={this.props.history} />
        {this.props.children}
        <Footer />
      </div>
    );
  }
}

export default ExcelContactsApp;