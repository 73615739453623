import React from 'react';
import {Link} from 'react-router';
import {first, without, findWhere} from 'underscore';


class TermsHome extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  handleClick(app) {
  }

  render() {

    
    return (
      <div>
        
        <section id="terms" className="text-body section">
          <div className="container">
              <div className="row">
                <div className="item col-md-12 col-sm-12 col-xs-12">
                    <h2 className="title">Terms of Use
                    </h2>
                </div>
                <div className="item col-md-12 col-sm-12 col-xs-12">
<p><b>Effective date: June 15, 2015; Last updated: December 25, 2021</b></p>
<p>By accessing or using the SamApp website, the SamApp service, or any applications (including mobile applications) made available by SamApp (together, the “Service”), however accessed, you agree to be bound by these terms of use ("Terms of Service"). The Service is owned or controlled by SamApp Co., Ltd. ("SamApp"). These Terms of Use affect your legal rights and obligations. If you do not agree to be bound by all of these Terms of Use, do not access or use the Service.</p>
<p>SamApp reserves the right to update and modify the Terms of Service at any time without notice. New features that may be added to the Service shall be subject to the Terms of Service. Should you continue to use the Service after any such modifications have been made, this shall constitute your agreement to such modifications.</p>
<p>Violation of any part of the Terms of Service will result in termination of your account.</p>
<h4 className="sub-title">Basic Terms</h4>
<p>1. You must be 16 years or older to use this Service.</p>
<p>2. You may not use the Service for any illegal or unauthorized purpose. You agree to comply with all laws, rules and regulations (for example, federal, state, local and provincial) applicable to your use of the Service and your Content (defined below), including but not limited to, copyright laws.</p>
<p>3. You are solely responsible for your conduct and any data, text, files, information, usernames, images, audio and video clips and other content or materials (collectively, "Content") that you submit, post or display on or via the Service.</p>

<h4 className="sub-title">Cancellation and Termination</h4>
<p>1. You’re free to stop using our Services at any time. We reserve the right to suspend or terminate your access to the Services with notice to you if:</p>
<ul>
<li>you’re in breach of these Terms,</li>
<li>your use of the Services would cause a real risk of harm or loss to us or other users.</li>
</ul>
<p>2. We won’t provide notice before termination where:</p>
<ul>
<li>you’re in material breach of these Terms,</li>
<li>doing so would cause us legal liability or compromise our ability to provide the Services to our other users, or</li>
<li>we're prohibited from doing so by law.</li>
</ul>

<h4 className="sub-title">Copyright and Ownership</h4>
<p>1. SamApp or its suppliers own the intellectual property rights to any and all protectable components of the Service, including but not limited to the name of the Service, artwork and end-user interface elements contained within the Service, many of the individual features, and the related documentation. You may not copy, modify, adapt, reproduce, distribute, reverse engineer, decompile, or disassemble any aspect of the Service which SamApp or its suppliers own.</p>
<p>2. SamApp claims no intellectual property rights over the Content you upload or provide to the Service. However, by using the Service to send Content, you agree that others may view and share your Content.</p>
<p>3. Content in the Services may be protected by others’ intellectual property rights. Please don’t copy, upload, download, or share content unless you have the right to do so.</p>
<p>4. We welcome feedback, but note that we may use comments or suggestions without any obligation to you.</p>

<h4 className="sub-title">General Conditions</h4>
<p>1. Your use of the Service, including any content, information or functionality contained within it, is provided "as is" and "as available" with no representations or warranties of any kind, either expressed or implied, including but not limited to, the implied warranties of merchantability, fitness for a particular purpose, and non-infringement. You assume total responsibility and risk for your use of this Service.</p>
<p>2. You agree not to resell, duplicate, reproduce, or exploit any part of the Service without the express written permission of SamApp.</p>
<p>3. You may not use the service to store, host, or send unsolicited email (spam) or SMS messages.</p>
<p>4. You may not use the service to transmit any viruses, worms, or malicious content.</p>
<p>5. SamApp makes no warranties regarding (i) your ability to use the Service, (ii) your satisfaction with the Service, (iii) that the Service will be available at all times, uninterrupted, and error-free (iv), the accuracy of mathematical calculations performed by the Service, and (v) that bugs or errors in the Service will be corrected.</p>
<p>6. SamApp, its affiliates and its sponsors are neither responsible nor liable for any direct, indirect, incidental, consequential, special, exemplary, punitive or other damages arising out of or relating in any way to your use of the Service, even if SamApp has been advised of the possibility of these damages, or for any claim by any other party. Your sole remedy for dissatisfaction with the Service is to stop using the Service.</p>
<p>7. If any provision of the Terms of Service is held invalid or otherwise unenforceable, the enforceability of the remaining provisions shall not be impaired thereby.</p>
<p>8. The failure of SamApp to exercise any right provided for herein shall not be deemed a waiver of any right hereunder. The Terms of Service sets forth the entire understanding between you and SamApp as to the Service and supersedes any prior agreements between you and SamApp (including, but not limited to, prior versions of the Terms of Service).</p>
<p>9. Any questions regarding the Terms of Service should be addressed to support@samapp.com.</p>

                </div>
              </div>
          </div>
        </section>
      </div>
    );
  }
}

export default TermsHome;

