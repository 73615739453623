import React from 'react';
import {Link} from 'react-router';
import ContactUs from './ContactUs';
import HomeStore from '../stores/HomeStore'
import HomeActions from '../actions/HomeActions';
import {first, without, findWhere} from 'underscore';


class Home extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  handleClick(app) {
    // HomeActions.vote(winner, loser);
  }

  render() {

    
    return (
      <div>
        <section id="samapp-carousel" className="samapp-carousel carousel slide">

          <div className="carousel-inner">
            <div className="item active">
               <div className="samapp-promo sa-contacts-promo">
                <div className="row">
                  <div className="overview col-md-8 col-sm-12 col-xs-12">
                    <h2 className="title sa-contacts">Export contacts from iPhone or Android to Excel</h2>
                    <p className="summary sa-contacts">You can use the spreadsheet to keep all your contacts in one place. It’s the best way to transfer contacts to and from your smartphone.</p>
                    <div className="download-area">
                        <ul className="btn-group list-inline">
                            <li className="ios-btn"><a href="https://itunes.apple.com/app/sa-contacts-lite-export-import/id385462419?mt=8">Download from the App Store</a></li>
                            <li className="android-btn"><a href="https://play.google.com/store/apps/details?id=com.samapp.excelcontacts.excelcontactslite">Get it from Google Play</a></li>
                        </ul>
                    </div>
                  </div>
                </div>

               </div>
            </div>
            <div className="item">
              <div className="samapp-promo sa-group-text-promo">
                <div className="row">
                  <div className="overview col-md-8 col-sm-12 col-xs-12">
                    <h2 className="title sa-group-text">Send bulk text on your phone using excel sheet as input</h2>
                    <p className="summary sa-group-text">Send personalized message to your customers. Create a schedule to send messages automatically at a specific time.</p>
                    <div className="download-area">
                        <ul className="btn-group list-inline">
                            <li className="ios-btn"><a href="https://play.google.com/store/apps/details?id=com.samapp.excelsms.excelsmsfull">Download from the App Store</a></li>
                            <li className="android-btn"><a href="https://play.google.com/store/apps/details?id=com.samapp.excelsms.excelsmslite">Get it from Google Play</a></li>
                        </ul>
                    </div>
                  </div>
                </div>

              </div>  
            </div>
          </div>

          <a className="left carousel-control" href="#samapp-carousel" role="button" data-slide="prev">
            <span className="fa fa-angle-left" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a className="right carousel-control" href="#samapp-carousel" role="button" data-slide="next">
            <span className="fa fa-angle-right" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>

        </section>

        <section id="features" className="apps section offset-header">
          <div className="container">
              <div className="row">
                <div className="item col-md-6 col-sm-12 col-xs-12">
                  <div className="app-icon pull-left">
                    <Link to='/excelcontacts'>
                      <img src={'/img/excelcontacts.png'} className='icon'/>
                    </Link>
                  </div>
                  <div className="app-desc">
                    <h2 className="app-title">SA Contacts
                    </h2>
                    <p>
                    Best way to transfer contacts to and from your phone.<br/>
                    Update, modify and maintain your contacts easily and conveniently on your computer using Excel, OpenOffice or any other Excel-compatible application.  
                    </p>
                    <div className="btn-link pull-right"><Link to='/excelcontacts'>Lean more<span className="fa fa-angle-double-right"></span></Link></div>
                  </div>
                </div>
                <div className="item col-md-6 col-sm-12 col-xs-12">
                  <div className="app-icon pull-left">
                    <Link to='/excelsms'>
                      <img src={'/img/excelsms.png'} className='icon'/>
                    </Link>
                  </div>
                  <div className="app-desc">
                    <h2 className="app-title">SA Group Text
                    </h2>
                    <p>
                    Best bulk SMS marketing tool. <br/>
                    If you are looking for an app to mass sms a long list of customized recipients, search no more as this app is for you. Works like a charm! It's so easy to edit those lists in excel and really helps productivity. Saved a tone of time using this.
                    </p>
                    <div className="btn-link pull-right"><Link to='/excelsms'>Lean more<span className="fa fa-angle-double-right"></span></Link></div>
                  </div>

                </div>
              </div>

              <div className="row">
                <div className="item col-md-6 col-sm-12 col-xs-12">
                  <div className="app-icon pull-left">
                    <Link to='https://mtestm.com' target="_blank">
                    <img src={'/img/mtestm.png'} className='icon'/>
                    </Link>
                  </div>
                  <div className="app-desc">
                    <h2 className="app-title">MTestM
                    </h2>
                    <p>
                    Create, publish, share your exams easily!<br/>
                    MTestM is an exam creator application that allows you to create, publish and share exams. Creating an exam has never been easier. you can add different types of questions on an Excel spreadsheet. 
                    </p>
                    <div className="btn-link pull-right"><Link to='https://mtestm.com' target="_blank">Lean more<span className="fa fa-angle-double-right"></span></Link></div>
                  </div>
                </div>
                
              </div>

          </div>

        </section>

        <ContactUs />
      </div>
    );
  }
}

export default Home;