import React from 'react';
import {Link} from 'react-router';

class SingleNavBar extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    
    return (
      <nav className='samapp-navbar navbar navbar-default navbar-static-top'>
        <div className='navbar-header'>
          <button type='button' className='navbar-toggle collapsed' data-toggle='collapse' data-target='#navbar'>
            <span className='sr-only'>Toggle navigation</span>
            <span className='icon-bar'></span>
            <span className='icon-bar'></span>
            <span className='icon-bar'></span>
          </button>
          <Link to='/' className='navbar-brand'>
            <img src={'/img/samapp.png'} className='icon'/>
          </Link>
        </div>
        <div id='navbar' className='navbar-collapse collapse'>
          <ul className='nav navbar-nav navbar-right'>
            <li><Link to='/#features' >Products</Link></li>
            <li><Link to='/#contact' >Contact</Link></li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default SingleNavBar;