import alt from '../../alt';

class ExcelContactsNavBarActions {
  constructor() {
    this.generateActions(
      'gotoFaq',
      'gotoManual',
      'gotoHome',
      'gotoFeatures',
      'gotoContactUs',
      'gotoHowItWorks'
    );
  }

}

export default alt.createActions(ExcelContactsNavBarActions);