import React from 'react';
import {Link} from 'react-router';
import {first, without, findWhere} from 'underscore';


class TCHelpHome extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  handleClick(app) {
  }

  render() {

    
    return (
        <section id="help" className="text-body section">
          <div className="container">
              <div className="item col-md-12 col-sm-12 col-xs-12">
<h4 className="sub-title">How to import your file via USB (iOS)?</h4>
<p>1. Connect your iPhone, iPad, or iPod touch to a PC or Mac with iTunes installed via a USB cable. Launch the iTunes application, unlock your iOS device, and click its icon on the toolbar in iTunes.</p>
<img src={'/img/teamcontacts/tc-help-1-1.jpg'} className='screenshot img-responsive center-block'/>
<p>2. Select the “Apps” category in the sidebar and scroll down in the right pane. After the list of home screens, you’ll eventually come to the “File Sharing” section.</p>
<p>3. Click TeamContacts Admin under File Sharing, and you’ll see its document library. Drag and drop your file to its document library.</p>
<img src={'/img/teamcontacts/tc-help-1-2.jpg'} className='screenshot img-responsive center-block'/>
<p>4. Launch TeamContacts Admin and choose the file you just added.</p>
<img src={'/img/teamcontacts/tc-help-1-3.jpg'} className='screenshot img-responsive center-block'/>

<h4 className="sub-title">How to import your file via Email (iOS)?</h4>
<p>1. Send an email with your file attached.</p>
<p>2. On your iPhone open the email and tap the attachment. choose TC Admin to open in.</p>
<img src={'/img/teamcontacts/tc-help-2-1.jpg'} className='screenshot img-responsive center-block'/>
<p>3. You will see a message in TeamContacts Admin that the file has been saved in File Sharing. </p>
<img src={'/img/teamcontacts/tc-help-2-2.jpg'} className='screenshot img-responsive center-block'/>
<p>4. Launch TeamContacts Admin and choose the file you just added.</p>

<h4 className="sub-title">How to move a file from SD card to TeamContacts Admin(Android)?</h4>
<p>1. Launch TeamContacts Admin, click the “+” button.</p>
<img src={'/img/teamcontacts/tc-help-3-1.jpg'} className='screenshot img-responsive center-block'/>
<p>2. Click Next button.</p>
<img src={'/img/teamcontacts/tc-help-3-2.jpg'} className='screenshot img-responsive center-block'/>
<p>3. choose the file which is in the root of SD card.</p>
<img src={'/img/teamcontacts/tc-help-3-3.jpg'} className='screenshot img-responsive center-block'/>

<h4 className="sub-title">How to create the excel file which can be imported to TeamContacts Admin?</h4>
<p>1. We have two sample files, you can download it and use one of them as a template.</p>
<p>2. You can also change the column headers if you also have an excel file.</p>
<p>3. Here’s the excel file looks like.</p>
<img src={'/img/teamcontacts/tc-spreadsheet-1.jpg'} className='screenshot img-responsive center-block'/>
<img src={'/img/teamcontacts/tc-spreadsheet-2.jpg'} className='screenshot img-responsive center-block'/>

              </div>
          </div>
        </section>
    );
  }
}

export default TCHelpHome;

