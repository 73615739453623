import React from 'react';
import {Link} from 'react-router';
import ContactUs from '../ContactUs';
import ExcelSMSNavBarStoreActions from '../../actions/excelsms/NavBarActions';

class ExcelSMSHome extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
     ExcelSMSNavBarStoreActions.gotoHome();
  }

  componentWillUnmount() {
  }

  render() {

    
    return (
      <div>
        <section id="samapp-carousel" className="samapp-carousel carousel slide">

          <div className="carousel-inner">
            <div className="item active">
               <div className="samapp-promo sa-group-text-promo">
                <div className="row">
                  <div className="overview col-md-8 col-sm-12 col-xs-12">
                    <h2 className="title sa-group-text">Send bulk text on your mobile phone using excel sheet as input</h2>
                    <p className="summary sa-group-text">Send personalized message to your customers<br/>
Create a schedule to send messages automatically at a specific time.</p>
                    <div className="download-area">
                        <ul className="btn-group list-inline">
                            <li className="ios-btn"><a href="https://play.google.com/store/apps/details?id=com.samapp.excelsms.excelsmsfull">Download from the App Store</a></li>
                            <li className="android-btn"><a href="https://play.google.com/store/apps/details?id=com.samapp.excelsms.excelsmslite">Get it from Google Play</a></li>
                        </ul>
                    </div>
                  </div>
                </div>

               </div>
            </div>
          </div>

        </section>

        <section id="features" className="app-features section offset-header">
          <div className="container">
              <div className="row">
                <div className="item col-md-9 col-sm-12 col-xs-12">
                  <div className="feature-screenshot pull-left">
                    <img src={'/img/excelsms/es-feature-1.jpg'} className='screenshot'/>
                  </div>
                  <div className="feature-desc">
                    <h2 className="title">Personalized message
                    </h2>
                    <p>
                    By sending personalized messages to your customers, you make a great first impression and become more memorable!<br/>
                    You can use SA Group Text to create the personalized messages. Each message has the same kind of information, yet the content of each message is unique. For example, in message to your customers, each message can be personalized to address each customer by name.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="item col-md-offset-3 col-md-9 col-sm-12 col-xs-12">
                  <div className="feature-screenshot pull-right">
                    <img src={'/img/excelsms/es-feature-2.jpg'} className='screenshot'/>
                  </div>
                  <div className="feature-desc">
                    <h2 className="title">Load messages from Excel
                    </h2>
                    <p>
                    You can use any Excel-compatible applications to create your group text messages. Be sure to save as an Excel 97-2003 Workbook. The file can contain only two columns : Mobile and Message. Here are more example spreadsheets for SA Group Text.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="item col-md-9 col-sm-12 col-xs-12">
                  <div className="feature-screenshot pull-left">
                    <img src={'/img/excelsms/es-feature-3.jpg'} className='screenshot'/>
                  </div>
                  <div className="feature-desc">
                    <h2 className="title">Send messages in background
                    </h2>
                    <p>
                    You can create a schedule in SA Group Text to send messages automatically at a specific time.<br/>
                    Due to iOS restriction, 3rd party app can not send message without user interaction, each message has to be confirmed by the user. so this feature is only available on Android.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="item col-md-offset-3 col-md-9 col-sm-12 col-xs-12">
                  <div className="feature-screenshot pull-right">
                    <img src={'/img/excelsms/es-feature-4.jpg'} className='screenshot'/>
                  </div>
                  <div className="feature-desc">
                    <h2 className="title">Auto SMS
                    </h2>
                    <p>
                    Once you upload an excel file for AutoSMS into a folder on Google Drive or Dropbox, the app will import it automatically and send messages at the time you specified. After all messages are sent out, the app will generate the delivery report and upload it to the same folder where the excel file for AutoSMS locates.<br/>
                    This feature is only available on Android.
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="item col-md-9 col-sm-12 col-xs-12">
                  <div className="feature-screenshot pull-left">
                    <img src={'/img/excelsms/es-feature-5.jpg'} className='screenshot'/>
                  </div>
                  <div className="feature-desc">
                    <h2 className="title">Delivery report
                    </h2>
                    <p>
                    SA Group Text can generate the delivery report. It's also an Excel spreadsheet. You can see all failed and undelivered messages. For those failed messages, there's an error code to let you know why it fails.<br/>
                    This feature is only available on Android.
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="item col-md-offset-3 col-md-9 col-sm-12 col-xs-12">
                  <div className="feature-screenshot pull-right">
                    <img src={'/img/excelsms/es-feature-6.jpg'} className='screenshot'/>
                  </div>
                  <div className="feature-desc">
                    <h2 className="title">Send both text and email
                    </h2>
                    <p>
                    SA Group Text can send email as well. After the message is sent to a recipient, the app can then send the same message to the recipient's mailbox. You have to add a column titled "EmailAddress" in the excel file and enter the email addresses.<br/>
                    This feature is only available on Android.
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="item col-md-9 col-sm-12 col-xs-12">
                  <div className="feature-screenshot pull-left">
                    <img src={'/img/excelsms/es-feature-7.jpg'} className='screenshot'/>
                  </div>
                  <div className="feature-desc">
                    <h2 className="title">Anti spam filter
                    </h2>
                    <p>
                    SA Group Text adds a new anti-spam-filter option call PreMessage. PreMessage is something like "&#123;FirstName&#125;, how are you?" or "&#123;FirstName&#125;, hope you are doing well." It can be mixed into the sequence of messages. The user can specify up to 10 PreMessages in an excel file.<br/>
                    This feature is only available on Android.
                    </p>
                  </div>
                </div>
              </div>

            </div>
        </section>
        <section id="download" className="app-features section">
          <div className="container">
              <div className="row">
                <div className="item col-md-12 col-sm-12 col-xs-12 text-center">
                  <h2 className="title">Download full-function application</h2>
                </div>
              </div>
              <div className="row">
                <div className="item col-md-12 col-sm-12 col-xs-12">
                  <p><font color="red">Since V3.6.10, we've removed delivery report feature and default sms handler capability due to Google’s SMS/Call Log restrictions.</font> Here's the application with full functionality. You can click the Download below to download the latest version and install it on your android phone. It will overwrite SA Group Text Lite app on your phone.</p>
                  <p>If you purchased the pro version from Google play market, here's the way to get the pro version of full-function app.</p>
                  <p className="no-bottom">1. Download and install the lite version.</p>
                  <p className="no-bottom">2. Go to the app's settings, select Upgrade to Pro and License Key. send the IMEI you see to support@samapp.com.</p>
                  <p className="no-bottom">3. We will send the license key to you soon after we receive your email.</p>
                </div>
              </div>
              <div className="row">
                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Lite</p>
                      <p className="no-bottom">V3.7.1s</p>
                      <Link to="/download/excelsms/excelsmslite-allperm-3.7.1.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Lite</p>
                      <p className="no-bottom">V3.6.33s</p>
                      <Link to="/download/excelsms/excelsmslite-allperm-3.6.33.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms-plugin.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Plugin 1</p>
                      <Link to="/download/excelsms/plugin1-release.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>
                
                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms-plugin.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Plugin 2</p>
                      <Link to="/download/excelsms/plugin2-release.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms-plugin.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Plugin 3</p>
                      <Link to="/download/excelsms/plugin3-release.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms-plugin.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Plugin 4</p>
                      <Link to="/download/excelsms/plugin4-release.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>

              </div>

              <div className="row">
                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms-plugin.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Plugin 5</p>
                      <Link to="/download/excelsms/plugin5-release.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>
                
                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms-plugin.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Plugin 6</p>
                      <Link to="/download/excelsms/plugin6-release.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms-plugin.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Plugin 7</p>
                      <Link to="/download/excelsms/plugin7-release.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms-plugin.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Plugin 8</p>
                      <Link to="/download/excelsms/plugin8-release.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>

              </div>

              <div className="row">
                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms-plugin.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Plugin 9</p>
                      <Link to="/download/excelsms/plugin9-release.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>
                
                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms-plugin.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Plugin 10</p>
                      <Link to="/download/excelsms/plugin10-release.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms-plugin.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Plugin 11</p>
                      <Link to="/download/excelsms/plugin11-release.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms-plugin.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Plugin 12</p>
                      <Link to="/download/excelsms/plugin12-release.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>

              </div>

              <div className="row">
                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms-plugin.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Plugin 13</p>
                      <Link to="/download/excelsms/plugin13-release.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>
                
                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms-plugin.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Plugin 14</p>
                      <Link to="/download/excelsms/plugin14-release.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms-plugin.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Plugin 15</p>
                      <Link to="/download/excelsms/plugin15-release.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms-plugin.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Plugin 16</p>
                      <Link to="/download/excelsms/plugin16-release.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>

              </div>

              <div className="row">
                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms-plugin.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Plugin 17</p>
                      <Link to="/download/excelsms/plugin17-release.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>
                
                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms-plugin.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Plugin 18</p>
                      <Link to="/download/excelsms/plugin18-release.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms-plugin.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Plugin 19</p>
                      <Link to="/download/excelsms/plugin19-release.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms-plugin.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Plugin 20</p>
                      <Link to="/download/excelsms/plugin20-release.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>

              </div>

              <div className="row">
                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms-plugin.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Plugin 21</p>
                      <Link to="/download/excelsms/plugin21-release.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>
                
                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms-plugin.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Plugin 22</p>
                      <Link to="/download/excelsms/plugin22-release.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms-plugin.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Plugin 23</p>
                      <Link to="/download/excelsms/plugin23-release.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms-plugin.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Plugin 24</p>
                      <Link to="/download/excelsms/plugin24-release.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>

              </div>

              <div className="row">
                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms-plugin.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Plugin 25</p>
                      <Link to="/download/excelsms/plugin25-release.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>
                
                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms-plugin.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Plugin 26</p>
                      <Link to="/download/excelsms/plugin26-release.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms-plugin.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Plugin 27</p>
                      <Link to="/download/excelsms/plugin27-release.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms-plugin.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Plugin 28</p>
                      <Link to="/download/excelsms/plugin28-release.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>

              </div>

              <div className="row">
                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms-plugin.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Plugin 29</p>
                      <Link to="/download/excelsms/plugin29-release.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>
                
                <div className="item col-md-3 col-sm-6 col-xs-12">
                  <div className="app-wrap">
                    <div className="left">
                      <img src={'/img/excelsms-plugin.png'} className='app-icon'/>
                    </div>
                    <div className="right">
                      <p className="no-bottom">SA Group Text Plugin 30</p>
                      <Link to="/download/excelsms/plugin30-release.apk" target="_blank"><div className="download-btn-no-top">Download</div>
                      </Link>
                    </div>
                  </div>
                </div>

              </div>

          </div>
        </section>

        <section id="download-samples" className="app-features section">
          <div className="container">
              <div className="row">
                <div className="item col-md-12 col-sm-12 col-xs-12 text-center">
                  <h2 className="title">What an Excel spreadsheet looks like</h2>
                </div>
              </div>
              <div className="row">
                <div className="item col-md-12 col-sm-12 col-xs-12 text-center">
                  <h4 className="title">Example 1</h4>
                </div>
              </div>
              <div className="row">
                <div className="item col-md-12 col-sm-12 col-xs-12 text-center">
                  <img src={'/img/excelsms/es-spreadsheet-1.jpg'} className='spreadsheet img-responsive center-block'/>
                </div>
                <div className="item col-md-12 col-sm-12 col-xs-12 text-center">
                  <Link to="/download/excelsms/sample1.xls" target="_blank"><div className="download-btn pull-right">Download</div>
                  </Link>
                </div>
              </div>

              <div className="row">
                <div className="item col-md-12 col-sm-12 col-xs-12 text-center">
                  <h4 className="title">Example 2</h4>
                </div>
              </div>
              <div className="row">
                <div className="item col-md-12 col-sm-12 col-xs-12 text-center">
                  <img src={'/img/excelsms/es-spreadsheet-2.jpg'} className='spreadsheet img-responsive center-block'/>
                </div>
                <div className="item col-md-12 col-sm-12 col-xs-12 text-center">
                  <Link to="/download/excelsms/sample2.xls" target="_blank"><div className="download-btn pull-right">Download</div>
                  </Link>
                </div>
              </div>

              <div className="row">
                <div className="item col-md-12 col-sm-12 col-xs-12 text-center">
                  <h4 className="title">Example 3</h4>
                </div>
              </div>
              <div className="row">
                <div className="item col-md-12 col-sm-12 col-xs-12 text-center">
                  <img src={'/img/excelsms/es-spreadsheet-3.jpg'} className='spreadsheet img-responsive center-block'/>
                </div>
                <div className="item col-md-12 col-sm-12 col-xs-12 text-center">
                  <Link to="/download/excelsms/sample3.xls" target="_blank"><div className="download-btn pull-right">Download</div>
                  </Link>
                </div>
              </div>

              <div className="row">
                <div className="item col-md-12 col-sm-12 col-xs-12 text-center">
                  <h4 className="title">Example 4</h4>
                </div>
              </div>
              <div className="row">
                <div className="item col-md-12 col-sm-12 col-xs-12 text-center">
                  <img src={'/img/excelsms/es-spreadsheet-4.jpg'} className='spreadsheet img-responsive center-block'/>
                </div>
                <div className="item col-md-12 col-sm-12 col-xs-12 text-center">
                  <Link to="/download/excelsms/sample4.xls" target="_blank"><div className="download-btn pull-right">Download</div>
                  </Link>
                </div>
              </div>

          </div>
        </section>

        <section id="faq" className="faq section">
          <div className="container">
            <div className="row">
              <h2 className="title text-center">Frequently Asked Questions</h2>
                <div className="faq-col col-md-6 col-sm-6 col-xs-12">
                    <div className="item">
                        <h3 className="question"><i className="fa fa-question-circle"></i>Is there anyway to automatically send through without any interruption by user?</h3>
                        <p className="answer">iOS does not allow 3rd party app send text without user interaction.<br/>
There’s 2 ways to send text without user interaction.<br/>
1.If you have jailbroken iPhone, please download ExcelSMS Auto which can send text without user interaction.<br/>
2.If you have android phone, please download SA Group Text from google play market. it can send text in background.</p>
                    </div>
                    <div className="item">
                        <h3 className="question"><i className="fa fa-question-circle"></i>Is there any way to make sure people know who’s texting them?</h3>
                        <p className="answer">Aside from identifying yourself in the message, sadly not really. If they have your number saved in their phone, then they would see your name instead of your number, but you would have to educate them on that.</p>
                    </div>
                    <div className="item">
                        <h3 className="question"><i className="fa fa-question-circle"></i>Can I change my SMS sending number to the name of a company?</h3>
                        <p className="answer">The SMS sending number used in messages cannot be changed to a company name. There are a number of reasons why we do not allow this number to be changed:<br/>
     1. If the number is changed, carriers may reject the SMS message as spam which could cause your message to be filtered.<br/>
     2. It’s also important to consider that SMS messages require recipients to have the option to opt-out. If the number is changed, recipients will not be able to opt-out of the texts that they receive.<br/>
     3. Some countries do not permit this information to be modified.</p>
                    </div>
                </div>
                <div className="faq-col col-md-6 col-sm-6 col-xs-12">
                    <div className="item">
                        <h3 className="question"><i className="fa fa-question-circle"></i>Why the app can not write to external sd card on Android 4.4+?</h3>
                        <p className="answer">Google has blocked write access to external storage devices in Android 4.4+. So you have to choose a folder on the internal storage. Then move the file between the external storage and internal storage using the built-in File manager.</p>
                    </div>
                    <div className="item">
                        <h3 className="question"><i className="fa fa-question-circle"></i>Is there a Character Limit on how long the text can be?</h3>
                        <p className="answer">All SMS messages have an industry set limit of 160 standard characters or 70 characters in non Latin alpahbet. However, it is possible to send messages that are longer than this limit by dividing the message into smaller parts that are sent as single SMS but seamlessly combined once the message reaches its destination. Modern mobile handsets are then able to "stitch" multipart messages back into a single, seamless message.<br/>
SA Group Text can send long text. on Android 4.4 and above, please choose SA Group Text as the default SMS application before you send messages through the app. otherwise your client might receive the separated message.</p>
                    </div>
                    <div className="item">
                        <h3 className="question"><i className="fa fa-question-circle"></i>Why did the app not support dual sim card?</h3>
                        <p className="answer">Dual sim are available but not documented and hence not officially supported by Google. We know some manufacturers provide their own way to access dual sim, but we could not find documented api from these manufacturers. we will try to support dual sim for some kind of phone in the future.<br/>
Now you have to change the default sim slot on the setting page if you want to choose another sim to send sms.</p>
                    </div>
                </div>

                <div className="faq-col col-md-6 col-sm-6 col-xs-12">
                    <div className="item">
                        <h3 className="question"><i className="fa fa-question-circle"></i>How many messages can I send at one time?</h3>
                        <p className="answer">The lite version can send 120 sms at one time. the pro version has no limit. But Android has a limit that one app can send up to 100 messages per hour. You can download our Group SMS plugins to extend this limit. After downloading 30 Group SMS plugins, you can send 3100 messages per hour.</p>
                    </div>
                </div>

            </div>
            <div className="more text-center">
                <h4 className="title">More questions?</h4>
                <a className="btn btn-lg btn-theme" href="mailto:support@samapp.com">Get in touch<i className="fa fa-arrow-circle-o-down"></i></a>
            </div>
          </div>
        </section>

        <section id="testimonials" className="testimonials section">
          <div className="container">
            <div class="row">
                <h2 className="title text-center">What do people think?</h2>
                <div className="item col-md-4 col-sm-4">
                    <div className="quote-box">
                        <i className="fa fa-quote-left"></i>
                        <blockquote className="quote">
                            Love this app! I used it on my S3 right before it died so had to go back to manual texting everyone. I now have a Note4 and it was so awesome to set up a text tonight and to be able to schedule it for 10am tomorrow!
                        </blockquote>
                    </div>
                    <div className="people row">
                        <img className="img-rounded user-pic col-md-5 col-sm-5 col-xs-12 col-md-offset-1 col-sm-offset-1" src="/img/excelsms/person-1.png" alt="" />
                        <p className="details text-center pull-left">
                            <span className="name">Suzanne McCarthy</span>
                        </p>                        
                    </div>
                </div>
                <div className="item col-md-4 col-sm-4">
                    <div className="quote-box">
                        <i className="fa fa-quote-left"></i>
                        <blockquote className="quote">
                            Very good app. Connect data base with phone Database exports a report to excel by windows scheduler to the drop box folder... this app reads the excell doc and sends the messages.
                        </blockquote>
                    </div>
                    <div className="people row">
                        <img className="img-rounded user-pic col-md-5 col-sm-5 col-xs-12 col-md-offset-1 col-sm-offset-1" src="/img/excelsms/person-2.png" alt="" />
                        <p className="details text-center pull-left">
                            <span className="name">Shane Arpas</span>
                        </p>                        
                    </div>
                </div>
                <div className="item col-md-4 col-sm-4">
                    <div className="quote-box">
                        <i className="fa fa-quote-left"></i>
                        <blockquote className="quote">
                            Brilliant! ExcelSMS application is best bulk SMS marketing tool both for Android and iOs users! (And I tried a lot of them) Best wishes to your business future! 》》Please add an ability to also import XLSX (newer office format) files.
                        </blockquote>
                    </div>
                    <div className="people row">
                        <img className="img-rounded user-pic col-md-5 col-sm-5 col-xs-12 col-md-offset-1 col-sm-offset-1" src="/img/excelsms/person-3.png" alt="" />
                        <p className="details text-center pull-left">
                            <span className="name">Nikola Pervan</span>
                        </p>                        
                    </div>
                </div>
            </div>

            <div class="row">
                <div className="item col-md-4 col-sm-4">
                    <div className="quote-box">
                        <i className="fa fa-quote-left"></i>
                        <blockquote className="quote">
                            Brilliant app. Just transfer the Excel file to the phone via USB and it all works amazingly.  Love that it is so flexible with being able to define unlimited merge fields in the texts.  And the additional plugins are a superb innovation, enabling me to send more than 100 sms messages per hour.
                        </blockquote>
                    </div>
                    <div className="people row">
                        <img className="img-rounded user-pic col-md-5 col-sm-5 col-xs-12 col-md-offset-1 col-sm-offset-1" src="/img/excelsms/person-null.png" alt="" />
                        <p className="details text-center pull-left">
                            <span className="name">R Pollard</span>
                        </p>                        
                    </div>
                </div>
                <div className="item col-md-4 col-sm-4">
                    <div className="quote-box">
                        <i className="fa fa-quote-left"></i>
                        <blockquote className="quote">
                            Thank you Developer This application helped me lot to expand my business.  For business people out there; this is the best messaging application you could ever have.
                        </blockquote>
                    </div>
                    <div className="people row">
                        <img className="img-rounded user-pic col-md-5 col-sm-5 col-xs-12 col-md-offset-1 col-sm-offset-1" src="/img/excelsms/person-null.png" alt="" />
                        <p className="details text-center pull-left">
                            <span className="name">Mark Arthur Pasco</span>
                        </p>                        
                    </div>
                </div>
                <div className="item col-md-4 col-sm-4">
                    <div className="quote-box">
                        <i className="fa fa-quote-left"></i>
                        <blockquote className="quote">
                            Does EXACTLY what it says it does Tried at least 8 other apps to send texts to regular groups of people. Either they used contact groups, but you had to then delete home and work numbers, or just didn't work. None let you create custom groups of custom NUMBERS. This does and works great.  Now I can invite a specific group of numbers  to a movie, or update on a surgery, whatever.
                        </blockquote>
                    </div>
                    <div className="people row">
                        <img className="img-rounded user-pic col-md-5 col-sm-5 col-xs-12 col-md-offset-1 col-sm-offset-1" src="/img/excelsms/person-6.png" alt="" />
                        <p className="details text-center pull-left">
                            <span className="name">SidandEmily Shaffer</span>
                        </p>                        
                    </div>
                </div>

                <div className="item col-md-4 col-sm-4">
                    <div className="quote-box">
                        <i className="fa fa-quote-left"></i>
                        <blockquote className="quote">
                            Easy to send group sms when you don't want to have them stored all in your phone. Only import Excell phone lists and start right away. Works great.
                        </blockquote>
                    </div>
                    <div className="people row">
                        <img className="img-rounded user-pic col-md-5 col-sm-5 col-xs-12 col-md-offset-1 col-sm-offset-1" src="/img/excelsms/person-null.png" alt="" />
                        <p className="details text-center pull-left">
                            <span className="name">Ar.Pf.</span>
                        </p>                        
                    </div>
                </div>

                <div className="item col-md-4 col-sm-4">
                    <div className="quote-box">
                        <i className="fa fa-quote-left"></i>
                        <blockquote className="quote">
                            I rely on text message marketing as the back bone of my business, sending approx 400-500 per week on iPhone 6. I've tried other programs but nothing is better than this. I don't need to personalize my texts but you can with this program. It's just a very useful App that does what I need it to do.
                        </blockquote>
                    </div>
                    <div className="people row">
                        <img className="img-rounded user-pic col-md-5 col-sm-5 col-xs-12 col-md-offset-1 col-sm-offset-1" src="/img/excelsms/person-null.png" alt="" />
                        <p className="details text-center pull-left">
                            <span className="name">RJINHB</span>
                        </p>                        
                    </div>
                </div>

                <div className="item col-md-4 col-sm-4">
                    <div className="quote-box">
                        <i className="fa fa-quote-left"></i>
                        <blockquote className="quote">
                            I use this every week, sometimes multiple times a week! It's just brilliant, took a little getting used to and working out, but now couldn't be without it! Just hope they keep the updates coming with the iPhone updates too. Fully recommend to anyone.
                        </blockquote>
                    </div>
                    <div className="people row">
                        <img className="img-rounded user-pic col-md-5 col-sm-5 col-xs-12 col-md-offset-1 col-sm-offset-1" src="/img/excelsms/person-null.png" alt="" />
                        <p className="details text-center pull-left">
                            <span className="name">Renrieu</span>
                        </p>                        
                    </div>
                </div>
            </div>

          </div>
        </section>

      </div>
    );
  }
}

export default ExcelSMSHome;

