import React from 'react';
import {Link} from 'react-router';
import TeamContactsNavBarStore from '../../stores/teamcontacts/NavBarStore';
import TeamContactsNavBarStoreActions from '../../actions/teamcontacts/NavBarActions';

class TeamContactsNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = TeamContactsNavBarStore.getState();
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    TeamContactsNavBarStore.listen(this.onChange);
  }

  componentWillUnmount() {
    TeamContactsNavBarStore.unlisten(this.onChange);
  }

  onChange(state) {
    this.setState(state);
  }

  render() {
    
    return (
      <nav className='samapp-navbar navbar navbar-default navbar-static-top'>
        <div className='navbar-header'>
          <button type='button' className='navbar-toggle collapsed' data-toggle='collapse' data-target='#navbar'>
            <span className='sr-only'>Toggle navigation</span>
            <span className='icon-bar'></span>
            <span className='icon-bar'></span>
            <span className='icon-bar'></span>
            <span className='icon-bar'></span>
          </button>
          <a className="scrollto navbar-brand" href="/teamcontacts">
              <img src={'/img/teamcontacts.png'} className='icon'/>
          </a>
          <a className="scrollto navbar-brand" href="/teamcontacts"><div className="navbar-title">TeamContacts</div></a>
          
        </div>
        <div id='navbar' className='navbar-collapse collapse'>
          <ul className='nav navbar-nav navbar-right'>
                <li className="nav-item"><a className="scrollto" href={"#features"}>Features</a></li>
                <li className="nav-item"><a className="scrollto" href={"#download"}>Download</a></li>
                <li className="nav-item"><a className="scrollto" href={"#faq"}>FAQ</a></li>
                <li className="nav-item"><a className="scrollto" href={"#pricing"}>Pricing</a></li>
                <li className="nav-item"><a href={"/teamcontacts/help"}>Help</a></li>
          </ul>
        </div>
      </nav>


    );
  }
}

export default TeamContactsNavbar;