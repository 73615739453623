import React from 'react';
import TCSingleNavBar from './TCSingleNavBar';
import Footer from '../Footer';

class TCSinglePage extends React.Component {
  render() {
    return (
      <div>
        <TCSingleNavBar history={this.props.history} />
        {this.props.children}
        <Footer />
      </div>
    );
  }
}

export default TCSinglePage;