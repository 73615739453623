import React from 'react';
import {Route, Router} from 'react-router';
import Root from './components/Root';
import SinglePage from './components/SinglePage';
import Home from './components/Home';
import TermsHome from './components/Terms';
import PrivacyHome from './components/Privacy';
import TeamContactsApp from './components/teamcontacts/App';
import TeamContactsHome from './components/teamcontacts/Home';
import ExcelContactsApp from './components/excelcontacts/App';
import ExcelContactsHome from './components/excelcontacts/Home';
import ExcelContactsPrivacyHome from './components/ExcelContactsPrivacy';
import ExcelSMSPrivacyHome from './components/ExcelSMSPrivacy';
import ExcelSMSApp from './components/excelsms/App';
import ExcelSMSHome from './components/excelsms/Home';
import TCSinglePage from './components/teamcontacts/TCSinglePage';
import TCHelp from './components/teamcontacts/TCHelp';

export default (
  <Router>
  	<Route component={Root}>
    	<Route path='/' component={Home} />
  	</Route>
    <Route component={SinglePage}>
      <Route path='/terms' component={TermsHome} />
      <Route path='/privacy' component={PrivacyHome} />
      <Route path='/excelcontacts/privacy' component={ExcelContactsPrivacyHome} />
      <Route path='/excelsms/privacy' component={ExcelSMSPrivacyHome} />
    </Route>
  	<Route component={ExcelContactsApp}>
    	<Route path='/excelcontacts' component={ExcelContactsHome} />
  	</Route>
    <Route component={ExcelSMSApp}>
      <Route path='/excelsms' component={ExcelSMSHome} />
    </Route>
  </Router>
);
