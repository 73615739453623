import React from 'react';
import {Link} from 'react-router';
import {first, without, findWhere} from 'underscore';


class PrivacyHome extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {

    
    return (
      <div>
        <section id="terms" className="text-body section">
          <div className="container">
              <div className="row">
                <div className="item col-md-12 col-sm-12 col-xs-12">
                    <h2 className="title">SA Group Text Privacy Policy
                    </h2>
                </div>
                <div className="item col-md-12 col-sm-12 col-xs-12">
<p><b>Effective date: June 15, 2015; Last updated: September 26, 2022</b></p>
<p>SamApp Co., Ltd. ("us", "we", or "our") operates SA Group Text website and mobile applications (the "Site" or "SA Group Text"). This Privacy Policy is meant to help you understand how we collect and use the personal information you provide when you use our Service.</p>

<h4 className="sub-title">1. What information does we collect?</h4>
<p>We collect information from you directly, like information you enter yourself, data about your consumption of content, and data from third-party platforms you connect with SA Group Text. We also collect some data automatically, like information about your device and what parts of our Services you interact with or spend time using.</p>
<p><b>1.1 Data You Provide to Us</b></p>
<p>We may collect different data from or about you depending on how you use the Services. Below are some examples to help you better understand the data we collect.</p>
<ul>
<li><b>Your Contacts</b>. In order to send message to your phone contacts, we need to access your phone contacts. Your contacts are only accessed locally in mobile application. we don't upload your contacts to our server.</li>
<li><b>Data About Your Accounts on Other Services</b>. You have the option to send message through other services such as Twilio and Nexmo in our application. You need to provide your account information to us, such as account id, auth token, to create the connection with those services. We only retain your account information in mobile application. we don't retain your account information on our server.</li>
<li><b>Communications and Support</b>. If you contact us for support or to report a problem or concern, we collect and store your contact information, messages, and other data about you like your name, email address, messages, and any other data you provide or that we collect through automated means (which we cover below). We use this data to respond to you and research your question or concern, in accordance with this Privacy Policy.</li>
</ul>

<p><b>1.2 Data We Collect through Automated Means</b></p>
<p>When you access the Services, we collect certain data by automated means, including:</p>
<ul>
<li><b>System Data</b>. Technical data about your computer or device, like your IP address, device type, operating system type and version, device identifiers(like Android Id, IDFA, IDFV), browser, browser language, domain and other systems data, and platform types.</li>
<li><b>Usage Data</b>. Usage statistics about your interactions with the Services, including content accessed, time spent on pages or the Service, pages visited, features used, click data, date and time, referrer, and other data regarding your use of the Services.</li>
<li><b>Approximate Geographic Data</b>. An approximate geographic location, including information like country, city, and geographic coordinates, calculated based on your IP address.</li>
</ul>
<p>The data listed above is collected through the use of server log files and tracking technologies.</p>

<h4 className="sub-title">2. How do we use your information?</h4>
<p>We collect information about you when you use the Service for a variety of reasons in order to support SA Group Text and to enable our team to continue to create engaging experiences for our users.</p>
<p><b>2.1 Providing, Analyzing, Improving and Developing the Service</b></p>
<p>We process the data we collect about you to operate, improve and develop the SA Group Text Service, including providing, maintaining, securing and improving our services, developing new ones, and protecting SA Group Text and our users. We are able to deliver our services, personalize content, and make suggestions for you by using this information to understand how you use and interact with our services. We conduct surveys and research, test features in development, and analyze the information we have to evaluate and improve products and services, develop new products or features, and conduct audits and troubleshooting activities.</p>
<p>Here are some examples of how we use this information:</p>
<ul>
<li><b>Providing, maintaining and improving the Service.</b> Your contacts we collect allows us to send group text. It also allows us learn about how you and others use SA Group Text to help create new activities and services.</li>
<li><b>Measuring, tracking and analyzing trends and usage in connection with your use or the performance of the Service.</b> In order to develop and enhance our products and deliver a consistent, secure and continuous experience, we need gather certain information to analyze usage and performance of the Service. We also use mobile analytics software to pursue our legitimate interests in operating and improving SA Group Text by allowing us to better understand the functionality of our mobile software on your device. This software may record information such as how often you use the application, the events and activities that occur within the application, aggregated usage and performance data.</li>
</ul>

<p><b>2.2 Communicating with You About the Service</b></p>
<p>We also use information you provide to us to provide you with relevant information about the Service and to assist you in using the Service if required. These communications may include:</p>
<ul>
<li><b>Sending you information we think you may find useful or which you have requested from us about our products and services.</b> We may send you information about the parts of SA Group Text you use or that may be relevant to you. While we primarily use our various product blogs to communicate this type of information and we expect to keep this type of email to a minimum, you may object to being contacted for these reasons using your personal contact information using the mechanisms provided in the SA Group Text service or using the information provided below.</li>
<li><b>Conducting surveys and collecting feedback about the Service.</b> We do this to evaluate the effectiveness of any updates we provide.</li>
<li><b>Delivering assistance or answering support requests.</b> We send you the information and support you request, including technical notices, security alerts, and other administrative messages to provide assistance for problems you may encounter with the Service.</li>
</ul>

<p><b>2.3 Protecting the Service and Maintaining a Trusted Environment</b></p>
<p>We use all categories of information we collect to maintain SA Group Text as a safe and secure platform for all our users, including appropriate enforcement of SA Group Text policies, as well as compliance with the law and applicable regulations. These efforts include:</p>
<ul>
<li><b>Preventing fraud, crime and abuse and the security and integrity of the Service.</b> This includes investigating, detecting and preventing or reporting fraud, misrepresentations, security breaches or incidents, other potentially prohibited or illegal activities or to otherwise help protect the service.</li>
<li><b>Complying with any applicable laws or regulations.</b> This includes appropriately responding to lawful requests for information from the government or third parties through legal process.</li>
<li><b>Contacting you to resolve disputes and provide assistance with the Service.</b></li>
</ul>

<p><b>2.4 Advertising, Analytics and Marketing</b></p>
<p>In order to support the SA Group Text business and provide our users with relevant information regarding the Service and opportunities presented by partners, We may communicate with you and others about opportunities, products, services, contests, promotions, discounts, incentives, surveys, and rewards offered by us and select partners. We may also displaying relevant advertising to you while using the Service.</p>

<p><b>2.5 Cookies and Data Collection Tools</b></p>
<p>We use cookies, which are small text files stored by your browser, to collect, store, and share data about your activities across websites, including on SA Group Text. They allow us to remember things about your visits to SA Group Text, like your preferred language, and to make the site easier to use. </p>
<p>SA Group Text and service providers acting on our behalf (like Google Analytics and third-party advertisers) use server log files and automated data collection tools like cookies, tags, scripts, customized links, device or browser fingerprints, and web beacons (together, “Data Collection Tools“) when you access and use the Services. These Data Collection Tools automatically track and collect certain System Data and Usage Data (as detailed in Section 1) when you use the Services. In some cases, we tie data gathered through those Data Collection Tools to other data that we collect as described in this Privacy Policy.</p>

<p><b>2.6 Other Uses</b></p>
<p>We may process data for any other purpose disclosed to you in connection with the Service from time to time. If we intend to process your personal data for a purpose other than that set out above and which is not compatible with the original processing purpose, we will provide you with relevant information prior to such processing and will obtain your consent where necessary.</p>

<h4 className="sub-title">3. Who We Share Your Data With</h4>
<p>We share certain data about you with other users and advertising companies who help us promote our Services. We may also share your data as needed for security, legal compliance, or as part of a corporate restructuring. Lastly, we can share data in other ways if it is aggregated or de-identified or if we get your consent.</p>
<p><b>3.1 Share Your Data For Advertising</b></p>

<p>We may share certain System Data with third-party advertisers and networks to display relevant advertising to you. Apple iOS, and Android OS each provide their own instructions on how to control in-app tailored advertising. For other devices and operating systems, you should review your privacy settings on that platform.</p>

<p><b>3.2 Share Your Data For Security and Legal Compliance</b></p>
<p>We may disclose your data to third parties if we (in our sole discretion) have a good faith belief that the disclosure is:</p>
<ul>
<li>Permitted or required by law;</li>
<li>Requested as part of a judicial, governmental, or legal inquiry, order, or proceeding.</li>
<li>Reasonably necessary as part of a valid subpoena, warrant, or other legally-valid request.</li>
<li>Reasonably necessary to enforce our Terms of Use, Privacy Policy, and other legal agreements.</li>
<li>Required to detect, prevent, or address fraud, abuse, misuse, potential violations of law (or rule or regulation), or security or technical issues.</li>
<li>Reasonably necessary in our discretion to protect against imminent harm to the rights, property, or safety of SA Group Text, our users, employees, members of the public, or our Services.</li>
<li>We may also disclose data about you to our auditors and legal advisors in order to assess our disclosure obligations and rights under this Privacy Policy.</li>
</ul>

<p><b>3.3 Share Your Data During a Change in Control</b></p>
<p>If SA Group Text undergoes a business transaction like a merger, acquisition, corporate divestiture, or dissolution (including bankruptcy), or a sale of all or some of its assets, we may share, disclose, or transfer all of your data to the successor organization during such transition or in contemplation of a transition (including during due diligence).</p>
<p><b>3.4 Share Your Data After Aggregation/De-identification</b></p>
<p>We may disclose or use aggregated or de-identified data for any purpose.</p>
<p><b>3.5 Share Your Data With Your Permission</b></p>
<p>With your consent, we may share data to third parties outside the scope of this Privacy Policy.</p>

<h4 className="sub-title">4. How Long We Retain Your Information</h4>
<p>SA Group Text does not retains your personal information.</p>
<p>We retain records of support tickets and other communications between SA Group Text and our users, for example support emails, survey responses, feedback submissions, or comments on our blogs or other posts, indefinitely in order to better manage our support processes, maintaining accurate business records, and identifying other trends. We reserve the right to publish these communications in order to help us clarify or respond to your request or to help us support other users.</p>

<h4 className="sub-title">5. How We Secure Your Information</h4>
<p>SA Group Text takes appropriate security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal data that we collect and store. These measures vary based on the type and sensitivity of the data. Unfortunately, however, no system can be 100% secured, so we cannot guarantee that communications between you and SA Group Text, the Services, or any information provided to us in connection with the data we collect through the Services will be free from unauthorized access by third parties. </p>

<h4 className="sub-title">6. Where Do We Store Your Information</h4>
<p>We store your information in the United States. We do not transfer any of your information outside the United States. If you are using the Services from outside the United States, you consent to the transfer, storage, and processing of your data in and to the United States or other countries.</p>

<h4 className="sub-title">7. What Rights Do You Have</h4>
<p>In some regions, such as the European Economic Area, you may have certain rights in relation to your personal data, including the right to ask us to update and correct your personal data, to request access to and obtain a copy of your personal data, erasure of your personal data if its retention is no longer necessary for the purposes for which it was originally collected, to restrict or object to the continued processing of personal data in some  circumstances and data portability (if appropriate).</p>
<p>If you would like to exercise any of the above rights, including to withdraw your consent (where our processing of your personal information relies on your consent), please contact us using the contact details stated below in this Privacy Policy.</p>
<p>We may in some cases be entitled to refuse to comply with your request. We will then inform you of the reasons for the refusal. We will not be able to perform the agreement with you if we no longer have the obligatory personal data about you, which we will notify you of as well.</p>

<h4 className="sub-title">8. Does SA Group Text allow children to use its Services</h4>
<p>The SA Group Text Service is designed for adults; we do not intend to collect personal information from children under the age of 16. If we learn we have collected or received personal information from a child under 16 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 16, please contact us using the contact details stated below in this Privacy Policy.</p>

<h4 className="sub-title">9. Changes to this Notice</h4>
<p>SA Group Text reserves the right to modify this Privacy Policy at any time, so please review it frequently. If we materially change how we use your personal information, we will notify, by email, and/or by means of a notice on our website prior to the change becoming effective. If you disagree with these changes, you can delete your account at any time and/or stop using the Service. Your continued use of the Service constitutes your acceptance of any changes to this Privacy Policy. The revised Privacy Policy supersedes all previous Privacy Policies.</p>

<h4 className="sub-title">10. Contact Us</h4>
<p>If you have any questions, concerns, or disputes regarding our Privacy Policy, please feel free to contact us at support@samapp.com</p>

                </div>
              </div>
          </div>
        </section>
      </div>
    );
  }
}

export default PrivacyHome;

