import React from 'react';
import ContactUsStore from '../stores/ContactUsStore';
import ContactUsActions from '../actions/ContactUsActions';

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = ContactUsStore.getState();
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    ContactUsStore.listen(this.onChange);
  }

  componentWillUnmount() {
    ContactUsStore.unlisten(this.onChange);
  }

  onChange(state) {
    this.setState(state);
  }

  handleSubmit(event) {
    event.preventDefault();

    var name = this.state.name.trim();
    var emailAddress = this.state.emailAddress.trim();
    var message = this.state.message;

    var valid = true;

    if (!name) {
      ContactUsActions.invalidName();
      this.refs.nameTextField.focus();
      valid = false;
    }

    if (!emailAddress) {
      ContactUsActions.invalidEmailAddress();
      valid = false;
    }
    if (!message || message.length < 20) {
      ContactUsActions.invalidMessage();
      valid = false;
    }
    if (valid) {
      ContactUsActions.addMessage(name, emailAddress, message);
    }
  }

  render() {
    return (
      <section id="contact" className="contact section has-pattern">
          <div className="container">
              <div className="row text-center">
                  <h2 className="title">Contact US</h2>
                  <div className="intro col-md-6 col-sm-12 col-xs-12 col-md-offset-3">
                      <p>We’d love to hear from you. Please feel free to contact us if you have any suggestions, questions or feedbacks.</p>
                      <ul className="list-unstyled contact-details">
                          <li><i className="fa fa-envelope"></i><a href="mailto:support@samapp.com">support@samapp.com</a></li>
                      </ul>
                  </div>
              </div>
              <div className="row text-center">
                  <div className="contact-form col-md-6 col-sm-12 col-xs-12 col-md-offset-3">                            
                      <form className="form" autocomplete="off" onSubmit={this.handleSubmit.bind(this)}>                
                          <div className={'form-group name ' + this.state.nameValidationState}>
                              <label className="sr-only" for="name">Name</label>
                              <input id="name" type="text" className="form-control" placeholder="Name:" ref='nameTextField' value={this.state.name}
                                onChange={ContactUsActions.updateName} />
                              <span className='help-block'>{this.state.nameHelpBlock}</span>
                          </div>
                          <div className={'form-group email ' + this.state.emailAddressValidationState}>
                              <label className="sr-only" for="email">Email</label>
                              <input id="email" type="email" className="form-control" placeholder="Email:" value={this.state.emailAddress}
                                onChange={ContactUsActions.updateEmailAddress}/>
                          </div>
                          <div className={'form-group message ' + this.state.messageValidationState}>
                              <label className="sr-only" for="message">Message</label>
                              <textarea id="message" className="form-control" rows="6" placeholder="Message:" value={this.state.message}
                                onChange={ContactUsActions.updateMessage}></textarea>
                              <span className='help-block'>{this.state.messageHelpBlock}</span>
                          </div>
                          <button type="submit" className="btn btn-lg btn-theme">Send Message</button>
                      </form>             
                  </div>
              </div>
              <div className="text-center">
                   <ul className="social-icons list-inline">
                      <li><a href="https://twitter.com/samappinc"><i className="fa fa-twitter"></i>
<script async src="//platform.twitter.com/widgets.js" charset="utf-8"></script></a></li>
                      <li><a href="https://www.facebook.com/samappcom/" ><i className="fa fa-facebook"></i></a></li>
                  </ul>
              </div>
          </div>
      </section>
    );
  }
}

export default ContactUs;