import React from 'react';
import {Link} from 'react-router';
import FooterStore from '../stores/FooterStore'
import FooterActions from '../actions/FooterActions';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = FooterStore.getState();
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    FooterStore.listen(this.onChange);
  }

  componentWillUnmount() {
    FooterStore.unlisten(this.onChange);
  }

  onChange(state) {
    this.setState(state);
  }

  render() {
    return (
      <footer className="footer">
        <div className="container">
            <p className="copyright pull-left">Copyright &copy; 2016 <Link to='/'>SamApp</Link></p>
            <ul className="links list-inline">
                <li><a href="/terms">Terms</a></li>
                <li><a href="/privacy">Privacy</a></li>
            </ul>
        </div>
    </footer>
    );
  }
}

export default Footer;