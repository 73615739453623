import alt from '../../alt';
import ExcelContactsNavBarActions from '../../actions/excelcontacts/NavBarActions';

class ExcelContactsNavBarStore {
  constructor() {
    this.bindActions(ExcelContactsNavBarActions);
    this.isHome = true;
    this.isFaq = false;
    this.isManual = false;
  }

  onGotoHome() {
    this.isHome = true;
    this.isFaq = false;
    this.isManual = false;
  }

  onGotoFaq() {
    this.isHome = false;
    this.isFaq = true;
    this.isManual = false;
  }

  onGotoManual() {
    this.isHome = false;
    this.isFaq = false;
    this.isManual = true;
  }

}

export default alt.createStore(ExcelContactsNavBarStore);

