import alt from '../alt';
import NavBarActions from '../actions/NavBarActions';

class NavBarStore {
  constructor() {
    this.bindActions(NavBarActions);
  }

}

export default alt.createStore(NavBarStore);